// extracted by mini-css-extract-plugin
export var container = "reviews-module--container--5ftbU";
export var details = "reviews-module--details--Fs7Vs";
export var header = "reviews-module--header--qzKAG";
export var link = "reviews-module--link--b09LB";
export var mainContent = "reviews-module--mainContent--jYSTU";
export var reviewDetails = "reviews-module--reviewDetails--t0vK6";
export var reviewGrade = "reviews-module--reviewGrade--KBPMo";
export var reviewTitle = "reviews-module--reviewTitle--JCBIM";
export var sidebarContent = "reviews-module--sidebarContent--3HMui";
export var yearHeader = "reviews-module--yearHeader--WbsKm";